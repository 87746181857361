import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import cookie from "vuecookie";
import axios from "axios";
import VueAxios from "vue-axios";
import Swal from "sweetalert2";

Vue.prototype.$cookie = cookie;
// ajax 功能 doc:https://www.npmjs.com/package/vue-axios
Vue.use(VueAxios, axios);
/* textarea內容轉換 */
Vue.prototype.$nl2br = function(text) {
    if (text) {
        return text.replace(/\n/g, "<br>");
    } else {
        return "";
    }
};
/* 清除html標籤 */
Vue.prototype.$strip_tags = function(str) {
    if (typeof str === "string") {
        return str.replace(/<(?:.|\n)*?>/gm, "");
    } else {
        return "";
    }
};
// swal 用法 this.$swal.fire({});
Vue.prototype.$swal = Swal;
Vue.config.productionTip = false;
store.state.mode = "dev";
// Cookie 處理
const value = `; ${document.cookie}`;
const parts = value.split(`; lan=`);
const web_lan = parts
    .pop()
    .split(";")
    .shift();
console.log("Website Language=" + web_lan);
switch (window.location.host) {
    case "www.kencnc.com":
    case "kencnc.com":
    case "n.kencnc.com":
        store.state.mode = "main";
        store.state.lan = "en";
        store.state.web = true;
        break;
    case "website.ken_test.com":
        store.state.lan = "en";
        store.state.web = true;
        break;
    case "www.kencnc.com.cn":
    case "kencnc.com.cn":
    case "n.kencnc.com.cn":
        store.state.mode = "main";
        store.state.lan = "cn";
        store.state.web = true;
        break;
    case "website_cn.ken_test.com":
        store.state.lan = "cn";
        store.state.web = true;
        break;
    case "www.kencnc.com.tw":
    case "kencnc.com.tw":
    case "n.kencnc.com.tw":
        location.href = "https://www.kencnc.com.cn";
        store.state.mode = "main";
        store.state.lan = "tw";
        store.state.web = true;
        break;
    case "website_tw.ken_test.com":
        store.state.lan = "tw";
        store.state.web = true;
        break;
    case "off.kencnc.com":
        store.state.lan = web_lan || "en";
        store.state.web = false;
        // 紀錄cookie
        document.cookie = "lan=" + store.state.lan;
        break;
    case "off.kencnc.com.cn":
        store.state.lan = web_lan || "cn";
        store.state.web = false;
        // 紀錄cookie
        document.cookie = "lan=" + store.state.lan;
        break;
    case "off.kencnc.com.tw":
        store.state.lan = web_lan || "tw";
        store.state.web = false;
        // 紀錄cookie
        document.cookie = "lan=" + store.state.lan;
        break;
    default:
        console.log("default" + web_lan);
        store.state.lan = web_lan || "tw";
        store.state.web = false;
        // 紀錄cookie
        document.cookie = "lan=" + store.state.lan;
        break;
}
// 如果是網站模式就把網站連結相同的部分存起來
if (store.state.web) {
    store.state.web_link = window.location.host.split(".com")[0] + ".com";
}
store.dispatch("get_lan");
store.dispatch("get_data");
// 代理商登入檢查
router.beforeEach(async (to, from, next) => {
    store.state.user.login_token = cookie.get("login_token");
    if (store.state.user.login_token) {
        store.state.user.id = cookie.get("admin_id");
        store.state.user.name = cookie.get("admin_name");
    } else {
        store.state.user.login_token = "";
    }
    if (to.meta.requireAuth) {
        if (store.state.user.login_token != "") {
            next();
        } else {
            next({
                path: "/agent/login"
            });
        }
    } else {
        next();
    }
});
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
