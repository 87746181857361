<template>
    <div>
        <div class="container bread_crumbs">
            <router-link to="/">
                <i class="fa fa-home"></i>
            </router-link>
            <span class="child_nav" v-for="p in get_path" :key="p.path">
                <router-link :to="p.link">{{ p.text }} </router-link>
            </span>
        </div>
        <transition name="slide-up-fade">
            <router-view />
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    computed: {
        get_path() {
            let vue = this,
                link = "",
                output_path = [],
                path = vue.$route.path.substr(1).split("/") || [];
            path.find(p => {
                // 如果為true的話就代表是最後一個麵包屑
                let final_path = false,
                    path_data = {},
                    output = {
                        path: p,
                        text: "",
                        link: ""
                    };
                link += "/" + p;
                switch (link) {
                    case "/news/view":
                        path_data = vue.$store.state.data.news.find(item => {
                            return item.id == vue.$route.params.id;
                        });
                        output.text = path_data.date + " " + path_data.name;
                        output.link = vue.$route.path;
                        final_path = true;
                        break;
                    case "/product/view":
                        path_data = vue.$store.state.data.product.find(item => {
                            return item.id == vue.$route.params.id;
                        });
                        output.text = path_data.model + " " + path_data.name;
                        output.link = vue.$route.path;
                        final_path = true;
                        break;
                    default:
                        if (vue.$store.state.language.layout.bread_crumbs[p]) {
                            output.text =
                                vue.$store.state.language.layout.bread_crumbs[
                                    p
                                ];
                            output.link = link;
                        }
                        break;
                }
                if (output.link != "") {
                    output_path.push(output);
                }
                return final_path;
            });
            return output_path;
        }
    }
};
</script>
<style lang="scss">
#content .bread_crumbs {
    font-size: 14px;
    padding: 0.5em 3em;
    margin-bottom: 1em;
    background-color: #fff;

    a {
        text-decoration: none;
        color: #999;
    }

    .child_nav {
        &:before {
            content: "/";
            display: inline;
            margin: 0 0.5em;
        }
        &:last-child a {
            color: #f08306;
        }
    }
}
</style>
