import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import moment from "moment";
import user from "./modules/user";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        lan: "tw",
        language: {},
        data: {},
        web: "",
        web_link: "",
        mode: "dev",
        init: false,
        get_data: {
            lan: false,
            data: false
        }
    },
    mutations: {
        switch_lan(state) {
            state.init = false;
            state.get_data.lan = false;
            state.get_data.data = false;
        },
        setlan(state, data) {
            state.language = data;
            state.get_data.lan = true;
            if (state.get_data.lan && state.get_data.data) {
                state.init = true;
            }
        },
        setdata(state, data) {
            state.data = data;
            state.get_data.data = true;
            if (state.get_data.lan && state.get_data.data) {
                state.init = true;
            }
        }
    },
    actions: {
        get_lan(context) {
            axios
                .get(
                    `/data/language/${
                        context.state.lan
                    }.json?t=_${moment().format("YYYYMM")}`
                )
                .then(res => {
                    context.commit("setlan", res.data);
                    // 更新頁面title
                    document.title = context.state.language.meta.title;
                });
        },
        get_data(context) {
            let folder = context.state.web ? "db" : "db_offline";
            axios
                .get(
                    `/data/${folder}/${
                        context.state.lan
                    }.json?t=_${moment().format("YYYYMMDDHHmm")}`
                )
                .then(res => {
                    context.commit("setdata", res.data);
                });
        },
        post_form(context, { payload, before, header }) {
            let store = this;
            if (typeof before === "function") {
                before();
            }
            if (!this.state.production_mode) {
                console.info("送出表單 payload", payload, "state", this.state);
            }
            let form = new FormData();
            for (let i in payload.data) {
                form.append(i, payload.data[i]);
            }
            let res = axios.post(`${payload.url}`, form, {
                headers: Object.assign(
                    {
                        "x-login-token": store.state.user.login_token,
                        "x-admin-id": store.state.user.id
                    },
                    header
                )
            });
            res = res.then(res => {
                console.info(res);
                if (res.data.status && res.data.status == "login") {
                    store.dispatch("clear_cookie");
                    location.href = "/";
                    return false;
                }
                return res;
            });

            return res;
        },
        switch_lan(context) {
            context.commit("switch_lan");
        }
    },
    modules: { user }
});
