<template>
    <div class="page" :class="{ scroll: scrolldown }" v-if="$store.state.init">
        <!-- Header -->
        <div id="header">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-lg-2">
                        <router-link to="/">
                            <img
                                class="logo"
                                :src="$store.state.language.logo"
                                alt="Ken Logo"
                            />
                        </router-link>
                    </div>
                    <div class="col-md-9 col-lg-10">
                        <div
                            class="menu_switch"
                            :class="show_menu ? 'open' : ''"
                            @click="show_menu = !show_menu"
                        >
                            <span></span>
                        </div>
                        <ul class="menu" :class="show_menu ? 'open' : ''">
                            <li>
                                <router-link to="/">{{
                                    $store.state.language.menu.home
                                }}</router-link>
                            </li>
                            <li>
                                <router-link to="/about">{{
                                    $store.state.language.menu.about
                                }}</router-link>
                            </li>
                            <li>
                                <router-link to="/product">{{
                                    $store.state.language.menu.product
                                }}</router-link>
                            </li>
                            <li>
                                <router-link to="/application">{{
                                    $store.state.language.menu.application
                                }}</router-link>
                            </li>
                            <li>
                                <router-link to="/news">{{
                                    $store.state.language.menu.news
                                }}</router-link>
                            </li>
                            <li v-show="$store.state.web">
                                <router-link to="/inquiry">{{
                                    $store.state.language.menu.inquiry
                                }}</router-link>
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    @click="show_language = true"
                                    >{{
                                        $store.state.language.menu.language
                                    }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Header -->
        <!-- Body -->
        <div id="content">
            <transition name="slide-up-fade">
                <router-view />
            </transition>
        </div>
        <!-- Body -->
        <!-- Foot -->
        <div id="foot">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 col-12 mb-md-0 mb-4 logo">
                        <img
                            src="../../../public/img/white_logo.png"
                            alt="kencnc.com"
                        />
                    </div>
                    <div class="col-md-8 col-12 content">
                        <div class="row">
                            <div class="col-md-5">
                                <div
                                    v-show="
                                        $store.state.language.layout.foot
                                            .address
                                    "
                                >
                                    {{
                                        $store.state.language.layout.foot
                                            .address
                                    }}
                                </div>
                                <div
                                    v-show="
                                        $store.state.language.layout.foot
                                            .address2
                                    "
                                >
                                    {{
                                        $store.state.language.layout.foot
                                            .address2
                                    }}
                                </div>
                                <div
                                    v-show="
                                        $store.state.language.layout.foot.tel
                                    "
                                >
                                    Tel：
                                    {{ $store.state.language.layout.foot.tel }}
                                </div>
                                <div
                                    v-show="
                                        $store.state.language.layout.foot.fax !=
                                            ''
                                    "
                                >
                                    Fax：
                                    {{ $store.state.language.layout.foot.fax }}
                                </div>
                                <div>
                                    E-mail：
                                    <a href="mailto:info@kencnc.com">
                                        info@kencnc.com
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-2 d-none d-md-inline-block">
                                <router-link class="sub_title" to="/product">
                                    {{
                                        $store.state.language.layout
                                            .bread_crumbs.product
                                    }}
                                </router-link>
                                <div
                                    v-for="c in $store.state.data.product_catg"
                                    :key="c.id"
                                >
                                    <router-link :to="`/product#${c.id}`">
                                        {{ c.name }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-md-2 d-none d-md-inline-block">
                                <router-link
                                    class="sub_title"
                                    to="/application"
                                >
                                    {{
                                        $store.state.language.layout
                                            .bread_crumbs.application
                                    }}
                                </router-link>
                                <div
                                    v-for="i in $store.state.data.industry"
                                    :key="i.id"
                                >
                                    <router-link :to="`/application#${i.id}`">
                                        {{ i.name }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-md-2 mt-md-0 mt-3 text-center d-none d-md-inline-block"
                    >
                        <img
                            src="../../../public/img/qrcode.png"
                            alt="Qr code"
                            style="max-width:100%"
                        />
                    </div>
                    <div
                        class="col-md-10 mt-5 d-none d-md-inline-block"
                        style="font-size:12px;color:#BDBDBD"
                    >
                        <!-- 電腦版 -->
                        {{ $store.state.language.copyright }}
                        <a
                            href="/agent/login"
                            v-show="$store.state.web"
                            style="margin-left: 3em"
                            >{{
                                $store.state.language.layout.foot.agent_login
                            }}</a
                        >
                    </div>
                    <div class="col-md-2 mt-2 mt-md-5 text-center contact_btn">
                        <a
                            href="https://www.youtube.com/channel/UCHEy22Rbh4QMmofYe23GvvA"
                            target="_blank"
                        >
                            <i class="fab fa-youtube-square"></i>
                        </a>
                        <a
                            href="https://www.linkedin.com/company/13654163/admin/"
                            target="_blank"
                        >
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                        <a href="javascript:void(0)" @click="show_weixin_qr()">
                            <i class="fab fa-weixin"></i>
                        </a>
                    </div>
                    <div
                        class="col-12 text-center mt-1 d-inline-block d-md-none"
                        style="font-size:12px;color:#BDBDBD"
                    >
                        <!-- 手機版 -->
                        {{ $store.state.language.copyright }}
                        <a
                            href="/agent/login"
                            v-show="$store.state.web"
                            style="margin-left: 3em"
                            >{{
                                $store.state.language.layout.foot.agent_login
                            }}</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <!-- Foot -->
        <div :class="show_language ? 'show' : ''" id="language_panel">
            <div class="bg">
                <div class="close" @click="show_language = false"></div>
                <div class="content">
                    <div class="buttons">
                        <!-- <a href="javascript:void(0)" @click="change_lan('tw')">繁體中文</a> -->
                        <a href="javascript:void(0)" @click="change_lan('cn')"
                            >简体中文</a
                        >
                        <a href="javascript:void(0)" @click="change_lan('en')"
                            >English</a
                        >
                        <a v-show="!$store.state.web" @click="update_offline()"
                            >更新資料</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import "../../sass/style.scss";
</style>
<script>
import axios from "axios";
export default {
    data() {
        return {
            scrolldown: false,
            show_language: false,
            show_menu: false,
            // 按鈕列產品選單顯示的產品
            catg_show_product: {},
            timer: ""
        };
    },
    mounted() {
        let vue = this;
        window.addEventListener("scroll", vue.onScroll);
        if (vue.$store.state.data.product_catg) {
            vue.$store.state.data.product_catg.forEach(catg => {
                let p = vue.$store.state.data.product.find(p => {
                    return p.id_product_catg == catg.id;
                });
                vue.$set(vue.catg_show_product, catg.id, p.main_img);
            });
        }
    },
    watch: {
        "$route.path"() {
            this.show_menu = false;
        }
    },
    computed: {
        get_product_list() {
            let vue = this,
                output = {};
            if (vue.$store.state.data.product_catg) {
                vue.$store.state.data.product_catg.forEach(catg => {
                    output[catg.id] = vue.$store.state.data.product.filter(
                        p => {
                            return p.id_product_catg == catg.id;
                        }
                    );
                });
            }
            return output;
        },
        get_application_list() {
            let vue = this,
                output = {};
            if (vue.$store.state.data.industry) {
                vue.$store.state.data.industry.forEach(catg => {
                    output[
                        catg.id
                    ] = vue.$store.state.data.industry_application.filter(p => {
                        return p.id_industry == catg.id;
                    });
                });
            }
            return output;
        }
    },
    methods: {
        onScroll() {
            this.scrolldown = window.scrollY > 20;
        },
        change_lan(lan) {
            console.log(
                "web:",
                this.$store.state.web,
                "mode:",
                this.$store.state.mode
            );
            if (this.$store.state.web) {
                let html = "",
                    hpage = location.pathname + location.hash;
                // 網頁版
                switch (lan) {
                    case "en":
                        html =
                            this.$store.state.mode == "main"
                                ? `https://${this.$store.state.web_link}`
                                : "http://website.ken_test.com";
                        break;
                    case "cn":
                        html =
                            this.$store.state.mode == "main"
                                ? `https://${this.$store.state.web_link}.cn`
                                : "http://website_cn.ken_test.com";
                        break;
                    default:
                        html =
                            this.$store.state.mode == "main"
                                ? `https://${this.$store.state.web_link}.tw`
                                : "http://website_tw.ken_test.com";
                        break;
                }
                location.href = html + hpage;
            } else {
                this.$store.dispatch("switch_lan");
                // 離線版
                this.$store.state.lan = lan;
                this.$store.dispatch("get_lan");
                this.$store.dispatch("get_data");
                window.scrollTo(0, 0);
                this.show_language = false;
                // Cookie處理
                const value = `; ${document.cookie}`;
                const parts = value.split(`; lan=`);
                parts
                    .pop()
                    .split(";")
                    .shift();
                // 紀錄cookie
                document.cookie = "lan=" + lan;
            }
        },
        show_weixin_qr() {
            this.$swal.fire({
                imageUrl: "/img/ken-weixin-qrcode.jpg",
                imageWidth: "100%",
                showConfirmButton: false
            });
        },
        update_offline() {
            let v = this;
            this.show_language = false;
            v.$swal
                .fire({
                    icon: "info",
                    title: "確定要開始更新資料嗎?",

                    showCancelButton: true
                })
                .then(() => {
                    v.$swal.fire({
                        title: "更新過程中請勿關閉軟體",
                        html: '<div id="percent">0%</div>',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen() {
                            v.timer = setInterval(function() {
                                axios.get("/update/process.txt").then(res => {
                                    console.log(res);
                                    document.getElementById(
                                        "percent"
                                    ).innerHTML = res.data + "%";
                                });
                            }, 1000);
                            axios.get(`/update/update.php`).then(() => {
                                clearInterval(v.timer);
                                v.$swal.fire({
                                    icon: "success",
                                    title: "更新完成! 請重開軟體",
                                    showConfirmButton: false,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false
                                });
                            });
                        }
                    });
                });
        }
    }
};
</script>
