import swal from "sweetalert2";
import cookie from "vue-cookies";
import router from "../../router/router.js";

export default {
    state: {
        login_token: "",
        id: "",
        name: ""
    },
    mutations: {
        set_user(state, user_data) {
            state.id = user_data.id;
            state.login_token = user_data.login_token;
            state.name = user_data.name;
        }
    },
    actions: {
        login(context, data) {
            context
                .dispatch("post_form", {
                    payload: {
                        url: "/api/index.php?act=login",
                        data: data.data
                    },
                    before: data.before ? data.before : ""
                })
                .then(res => {
                    if (res.data.status == "ok") {
                        cookie.set("login_token", res.data.token);
                        cookie.set("admin_id", res.data.id);
                        cookie.set("admin_name", res.data.name);
                        context.commit(
                            "set_user",
                            Object.assign(res.data, {
                                login_token: res.data.token
                            })
                        );
                    }
                    if (typeof data.callback === "function") {
                        data.callback(res);
                    }
                });
        },
        logout(context) {
            context.dispatch("clear_cookie");
            swal.fire({
                title: this.state.language.agent.logout_notify,
                timer: 1000,
                icon: "success",
                showConfirmButton: false,
                allowOutsideClick: false
            }).then(() => {
                router.push({
                    path: "/agent/login"
                });
            });
        },
        clear_cookie(context) {
            cookie.remove("login_token");
            cookie.remove("admin_id");
            cookie.remove("admin_name");
            context.commit("set_user", {
                id: "",
                login_token: "",
                name: ""
            });
        }
    }
};
