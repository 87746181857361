import Vue from "vue";
import VueRouter from "vue-router";
import layout from "../views/layout/layout.vue";
import empty from "../views/layout/empty.vue";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            component: layout,
            children: [
                {
                    path: "",
                    component: () => import("../views/index.vue")
                },
                {
                    path: "/en",
                    redirect: "/"
                },
                {
                    path: "about",
                    component: empty,
                    children: [
                        {
                            path: "",
                            component: () => import("../views/about.vue")
                        }
                    ]
                },
                {
                    path: "news",
                    component: empty,
                    children: [
                        {
                            path: "",
                            component: () => import("../views/news/index.vue")
                        },
                        {
                            path: "view/:id",
                            component: () => import("../views/news/view.vue")
                        }
                    ]
                },
                {
                    path: "product",
                    component: empty,
                    children: [
                        {
                            path: "",
                            component: () =>
                                import("../views/product/index.vue")
                        },
                        {
                            path: "view/:id",
                            component: () => import("../views/product/view.vue")
                        }
                    ]
                },
                {
                    path: "application",
                    component: empty,
                    meta: { requireAuth: true },
                    children: [
                        {
                            path: "",
                            component: () =>
                                import("../views/product/application.vue")
                        }
                    ]
                },
                {
                    path: "inquiry",
                    component: empty,
                    children: [
                        {
                            path: "",
                            component: () =>
                                import("../views/inquiry/inquiry.vue")
                        },
                        {
                            path: "as",
                            component: () => import("../views/inquiry/as.vue")
                        },
                        {
                            path: "as/:customer/:machine",
                            component: () => import("../views/inquiry/as.vue")
                        },
                        {
                            path: "survey",
                            component: () =>
                                import("../views/inquiry/survey.vue")
                        },
                        {
                            path: "survey/:customer/:machine",
                            component: () =>
                                import("../views/inquiry/survey.vue")
                        }
                    ]
                },
                {
                    path: "agent",
                    component: empty,
                    children: [
                        {
                            path: "login",
                            component: () => import("../views/agent/login.vue")
                        },
                        {
                            path: "",
                            redirect: "/agent/list",
                            component: () =>
                                import("../views/agent/agent_empty.vue"),
                            children: [
                                {
                                    path: "list",
                                    meta: { requireAuth: true },
                                    component: () =>
                                        import("../views/agent/list.vue")
                                },
                                {
                                    path: "change_pw",
                                    meta: { requireAuth: true },
                                    component: () =>
                                        import("../views/agent/change_pw.vue")
                                },
                                {
                                    path: "customer_register",
                                    meta: { requireAuth: true },
                                    component: () =>
                                        import("../views/agent/customer_register.vue")
                                },
                                {
                                    path: "customer_register/:id",
                                    meta: { requireAuth: true },
                                    component: () =>
                                        import("../views/agent/customer_register.vue")
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            path: "*",
            component: () => import("../views/404.vue")
        }
    ]
});

export default router;
